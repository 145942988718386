import React, { useEffect } from "react";

function HubspotForm ( props ) {

    useEffect(() => {
        const script = document.createElement('script');
    
        script.src = "https://js.hsforms.net/forms/v2.js";
        script.async = true;
        
        script.addEventListener("load", () => {
            if (window.hbspt) {
                window.hbspt.forms.create({
                    portalId: props.portalId,
                    formId: props.formId,
                    target: "#hubspotForm"
                });
            }
        });

        document.body.appendChild(script);

        return () => {
          document.body.removeChild(script);
        }
      }, []);


    return (
        <div id="hubspotForm"></div>
    )
}

HubspotForm.defaultProps = {
    portalId: "6671754",
    formId: "f88599a1-6eba-4146-ac21-9cb9938798f6",
}

export default HubspotForm