import React from 'react'
import ImageComponent from './imageContent'
import TextComponent from './textContent'
import FullWidthComponent from './fullWidthContent'
import QuoteComponent from './quoteContent'
import RelatedComponent from  './relatedContent'
import CtaContent from './ctaContent'
import HubspotForm from '../hubspotForm/hubspotForm'
import './flexcontent.scss'

const FlexContent = (props)  => {

    const flex = props.page.pageFlexContent

    return (
        <section className='flex-content'>
            <div className='container'>
                { flex &&
                    flex.map((row, key) => {
                        // set odd or even classes
                        const type = key % 2 ? 'odd' : 'even'

                        // content : text with image
                        if (row.internal.type === 'ContentfulContentTextWithImage') {
                            return (
                                <div className={`content grid--two content--${type}`} key={key}>
                                    <TextComponent data={row}/>
                                    <ImageComponent data={row}/>
                                </div>
                            )
                        }

                        // content : quotes
                        if (row.internal.type === 'ContentfulContentQuotes') {
                            return (
                                <div className={`content grid--one content--${type}`} key={key}>
                                    <QuoteComponent data={row}/>
                                </div>
                            )
                        }

                        // content : full width text
                        if (row.internal.type === 'ContentfulContentFullWidthText') {
                            return (
                                <div className={`content grid--one content--${type}`} key={key}>
                                    <FullWidthComponent data={row}/>
                                </div>
                            )
                        }

                        // content : video
                        if (row.internal.type === 'ContentfulContentVideo') {
                            return (
                                <div className={`content grid--one content--${type}`} key={key}>
                                    <CtaContent data={row}/>
                                </div>
                            )
                        }

                        // content : related
                        if (row.internal.type === 'ContentfulContentRelated') {
                            return (
                                <div className={`content grid--one content--${type}`} key={key}>
                                    <RelatedComponent data={row} locale={props.locale}/>
                                </div>
                            )
                        }

                        // content : hubspot form
                        if (row.internal.type === 'ContentfulContentHubspot') {
                            return (
                                <div className={`content grid--one content--${type}`} key={key}>
                                    <HubspotForm formId={row.formId}/>
                                </div>
                            )
                        }

                        return false
                    })
                }
            </div>
        </section>
    )
}

export default FlexContent

