import React from 'react'
import RichText from '../../richText'

const ImageComponent = (props)  => {
    return (
        <div className="content__field content--text">
            <div className="content__inner">
                <h4 className='content__title'>{props.data.pageTitle}</h4>
                <div className='content__description'><RichText data={props.data.pageDescription} /></div>
            </div>
        </div>
    )

}

export default ImageComponent

