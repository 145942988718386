import React from 'react'
import RichText from '../../richText'

const QuoteComponent = (props)  => {
    if (props.data.pageQuoteVariant !== true) {
        return (
            <div className="content__field content--quote">
                <div className="block__quote">
                    <div className="block__quote-inner">
                        <h4>"{props.data.pageQuote}"</h4>
                        <p>{props.data.pageQuoteAuthor}</p>
                    </div>
                </div>
                <div className="block__text">
                    <h4 className='content__title'>{props.data.pageTitle}</h4>
                    <div className='content__description'><RichText data={props.data.pageDescription} /></div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="content__field content--quote-variant">
                <h4>{props.data.pageQuote}</h4>
                <div className='content__description'><RichText data={props.data.pageDescription} /></div>
                <h5 className='content__title bold'>{props.data.pageTitle}</h5>
            </div>
        )
    }

}

export default QuoteComponent

