import React from 'react'
import Img from 'gatsby-image'
import RichText from '../../richText'

const FullWidthComponent = (props)  => {
    // console.log(props)
    return (
        <div className="content__field content--fullwidth">
            <div className="content__inner">
                <h4 className='content__title'>{props.data.pageTitle}</h4>
                <div className='content__description'><RichText data={props.data.pageDescription} /></div>
                { props.data.pageImage &&
                    <Img fluid={props.data.pageImage.fluid}/>
                }
            </div>
        </div>
    )

}

export default FullWidthComponent

