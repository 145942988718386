import React from 'react'
import { Link } from 'gatsby'
import { linkToId } from '../../../assets/js/utils'
import t from '../../../i18n'

const RelatedComponent = ( props )  => {

    // Define translations
    const translations = t( 'Buttons', props.locale )

    console.log(props.locale)

    return (
        <section className='related'>
            <div className='container padding'>
                <h2 className='related__global-title color--kraaijeveld'><span>{props.data.relatedTitle}</span> {props.data.relatedSubtitle}</h2>
                <div className="grid--three">
                    { props.data.relatedContent &&
                        props.data.relatedContent.map((rel, key) => {
                            return (
                                <div className='related__item' key={key}>
                                    {rel.pageImage &&
                                        <img src={rel.pageImage.fluid.src} className='related__image' alt=''/>
                                    }
                                    <div className='related__content'>
                                        <div className='related__inner'>
                                            <h4 className='related__title color--kraaijeveld font--handwritten'><span>{rel.pageTitle}</span> {rel.pageSubtitle}</h4>
                                            <Link to={linkToId(rel.id)} className='button button--tertiary'>{translations.readArticle}</Link>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </section>
    )
}

export default RelatedComponent
