import React from 'react'
import Img from 'gatsby-image'

function generateBlob() {
    let percentage1 = Math.floor(Math.random()*(75-25+1)+25)
    let percentage2 = Math.floor(Math.random()*(75-25+1)+25)
    let percentage3 = Math.floor(Math.random()*(75-25+1)+25)
    let percentage4 = Math.floor(Math.random()*(75-25+1)+25)
    let percentage11 = 100 - percentage1;
    let percentage21 = 100 - percentage2;
    let percentage31 = 100 - percentage3;
    let percentage41 = 100 - percentage4;
    let borderRadius = `${percentage1}% ${percentage11}% ${percentage21}% ${percentage2}% / ${percentage3}% ${percentage4}% ${percentage41}% ${percentage31}%`;
    return borderRadius
}

const ImageComponent = (props)  => {
    return (
        <div className="content__field content--image">
            <div className="content__inner">
                {props.data.pageImage?.fluid !== null &&
                    <Img fluid={props.data.pageImage.fluid}/>
                }
                {/* <div className="blob" style={{borderRadius: generateBlob()}}/> */}
            </div>
        </div>
    )

}

export default ImageComponent

