import React from 'react'
import ModalVideo from 'react-modal-video'
import Img from 'gatsby-image'
import RichText from '../../richText'

export default class CtaContent extends React.Component {

    constructor () {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }

    openModal () {
        this.setState({isOpen: true})
    }

    render () {
        return (
            <div className="content__field content--cta">
                <div className="block__text">
                    { this.props.data.pageImage !== null &&
                        <Img fluid={ this.props.data.pageImage.fluid } />
                    }
                    <h3 className='content__title'>{this.props.data.pageTitle}</h3>
                    {this.props.data.pageDescription !== null &&
                        <RichText data={this.props.data.pageDescription}/>
                    }
                    <span className='button button--secondary' onClick={this.openModal}>{this.props.data.pageButtonText}</span>
                    <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId={this.props.data.pageButtonLink} onClose={() => this.setState({isOpen: false})} />
                </div>
            </div>
        )
    }
}
